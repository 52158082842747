import classes from './ProductLabels.module.css';

const ProductLabels = ({ product: { inStock, labels=[] } }) => {
    return (
        <div className={classes.labels}>
            {
                labels.map(({ label, color, textColor='#ffffff' }, index) => {
                    return <div key={String('label'+index)} className={classes.label} style={{backgroundColor: color, color: textColor}}>{ label }</div>
                })
            }
        </div>
    );
}

export default ProductLabels;