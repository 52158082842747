import { useEffect, useReducer } from 'react';

import Kerstpakketten from '../lib/api/Kerstpakketten';

const reducer = (state, action) => {
    switch (action.type) {
        case 'product/request':
            return  {
                ...state,
                error: null,
                loading: true
            };
        case 'product/success':
            return {
                ...state,
                data: action.payload,
                error: null,
                loading: false
            };
        case 'product/error':
            return {
                ...state,
                error: action.payload,
                loading: false
            };
      default:
        throw new Error();
    }
}


const useProduct = (product_key, preloaded_state=null) => {

    const [state, dispatch] = useReducer(reducer, {
        data: preloaded_state,
        error: null,
        loading: (preloaded_state !== null)
    });

    useEffect(() => {

        let cancelRequest = false;

        const fetchData = async () => {

            dispatch({ type: 'product/request' });

            try {
                const { data } = await Kerstpakketten.getProduct(product_key);

                const product = data[0]?.attributes || null;

                product.id = data[0]?.id || null;

                product.categories = product.categories.data.map(({ attributes }) => {
                    return attributes;
                });

                if (product?.labels?.data) {
                    product.labels = product.labels.data.map(({ attributes }) => {
                        return attributes;
                    });
                }

                if (product?.image?.data) {
                    product.image = {
                        ...product?.image?.data?.attributes,
                        id: product?.image?.data?.id
                    }
                } else {
                    product.image = null;
                }

                if (product?.video?.data) {
                    product.video = {
                        ...product?.video?.data?.attributes,
                        id: product?.video?.data?.id
                    }
                } else {
                    product.video = null;
                }
                
                if (!cancelRequest) { dispatch({ type: 'product/success', payload: product }); }
            } catch (error) {
                if (!cancelRequest) { dispatch({ type: 'product/error', payload: error }); }
            }
        };

        fetchData();

        return () => {
            cancelRequest = true;
        };
    }, [product_key]);

    //const product = state.data ? state.data : null;

    return {
        product: state.data,
        error: state.error,
        loading: state.loading
    };
}

export default useProduct;