import React, { useState, useReducer, useRef } from 'react';

import { Builder } from '@builder.io/react/lite';

import ReCAPTCHA from 'react-google-recaptcha';

import { Col, Row } from '../../components/grid';

import useLocations from '../../hooks/useLocations';

import {
    Input,
    Button,
    Form,
    Select,
    Textarea
} from '../../components/form';

import Kerstpakketten from '../../lib/api/Kerstpakketten';
import Config from '../../lib/config/Config';

import classes from './ContactForm.module.css';

const initialState = {
    success: false,
    error: null,
    sending: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'form/request':
            return {
                ...state,
                success: false,
                error: null,
                sending: true
            };
        case 'form/success':
            return {
                ...state,
                success: true,
                error: null,
                sending: false
            };
        case 'form/error':
            return {
                ...state,
                success: false,
                error: action.payload,
                sending: false
            };
        default:
            throw new Error();
    }
}

const ContactForm = () => {
    const [inputs, setInput] = useState({ location: '', loyaltynumber: '', name: '', email: '', phone: '', message: '', question: '' });
    const [state, dispatch] = useReducer(reducer, initialState);
    const recaptchaRef = useRef(null);

    const { locations, loading } = useLocations();

    const onChange = ({ target: { name, value } }) => {
        setInput({
            ...inputs,
            [name]: value
        });
    }

    const onSubmit = async (valid, resetForm) => {

        if (valid) {

            const token = await recaptchaRef.current.executeAsync();

            try {
                dispatch({ type: 'form/request' });
                
                await Kerstpakketten.sendContact({
                    ...inputs,
                    recaptcha: token || ''
                });

                dispatch({ type: 'form/success' });
                setInput({ location: '', loyaltynumber: '', name: '', email: '', phone: '', message: '', question: '' });
                resetForm();

            } catch (error) {
                dispatch({ type: 'form/error', payload: error });
            }

            recaptchaRef.current.reset();
        }
    }

    return (
        <Form onSubmit={onSubmit} id="contact-form" className={classes.form}>
            <Row>
                {
                    state.error && <Col xs={12} ><div className={classes.error}>Formulier kon niet worden verstuurd. Probeer het later nogmaals.</div></Col>
                }
                {
                    state.success && <Col xs={12} ><div className={classes.success}>Formulier succesvol verstuurd!</div></Col>
                }
                <Col xs={12}><span className={classes.required}>* Verplicht</span></Col>

                <Col md={12}>
                    <Select name="location" label="Vestiging" loading={loading} onChange={onChange} value={inputs.location}>
                        <option value=''>Selecteer vestiging</option>
                        {
                            locations.map(({ key, name }) => {
                                return <option key={key} value={key}>{name}</option>
                            })
                        }
                    </Select>
                </Col>

                <Col xs={12}>
                    <Input name="loyaltynumber" label="Klantnummer" placeholder="Klantnummer" onChange={onChange} value={inputs.loyaltynumber} />
                </Col>
                <Col xs={12}>
                    <Input name="name" label="Naam" placeholder="Naam" onChange={onChange} value={inputs.name} required />
                </Col>
                <Col xs={12}>
                    <Input name="email" label="E-mailadres" placeholder="voorbeeld@email.com" onChange={onChange} value={inputs.email} required />
                </Col>
                <Col xs={12}>
                    <Input name="phone" label="Telefoonnummer" placeholder="Telefoonnummer" onChange={onChange} value={inputs.phone} required />
                </Col>
                <Col md={12}>
                    <Select name="question" label="Vraag" loading={loading} onChange={onChange} value={inputs.question}>
                        <option value=''>Vraag</option>

                        <option value='HANOS Kerstpakketten'>HANOS Kerstpakketten</option>
                        <option value='HANOS Gifts'>HANOS Gifts</option>
                        <option value='Zelf samenstellen'>Zelf samenstellen</option>
                        <option value='Demo wereldse keuze'>Demo HANOS wereldse keuze</option>

                    </Select>
                </Col>
                <Col xs={12}>
                    <Textarea name="message" label="Opmerkingen" placeholder="Type hier uw speciale vragen en/of opmerkingen" onChange={onChange} value={inputs.message} />
                </Col>
                <Col xs={12}>
                    <Button variant="secondary" type="submit" loading={state.sending} id="contact-submit" style={{ float: 'right' }}>
                        <span>Versturen</span>
                    </Button>
                </Col>
            </Row>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={Config.get('captcha').site_key}
            />
        </Form>
    );
}

export default ContactForm;

Builder.registerComponent(ContactForm, {
    name: "Contact from"
});